import Vue from 'vue/dist/vue.esm'
import TurbolinksAdapter from 'vue-turbolinks'
import axios from 'axios'
import AxiosRequest from "../utils/axios-request"


Vue.use TurbolinksAdapter

document.addEventListener 'turbolinks:load', ->
  el = document.getElementById 'show-tag'
  return false unless el?
  new Vue {
    el
    data: -> {
      tags: []
      page: 1
      check: []
    }
    mounted: ->
      @id = document.getElementById('id').dataset.id
      @fetchTags()
      @send()
    methods:
      fetchTags: ->
        axios.get("/customers/account/#{@id}/tags.json", params: {page: @page}).then ({data}) =>
          @tags.push data.tags...
          @page += 1
      send: ->
        @id = document.getElementById('id').dataset.id
        for i in @check
          AxiosRequest('/tags', {customer_tag: {customer_id: @id, tag_id: i }}, 'post').then =>
#            alert('Gracias por registrar tus preferencias')

  }
